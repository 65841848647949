import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Link, { withPrefix } from 'gatsby-link'
import Helmet from "react-helmet"
import axios from 'axios'
import Reveal from 'react-reveal/Reveal'

import Title from '../components/title'
import TemplateWrapper from '../components/layout'

class CaseStudyPage extends Component {
  render() {

    let caseStudies = this.props.data.allWordpressWpCaseStudy.edges

    return (
      <TemplateWrapper {...this.props}>
        <Helmet>
          <title>{ 'Case Studies' }</title>
          <meta name="description" content={ 'A collection of case studies highlighting the range of skills at Round 3, including: lighting, sound, videography, photography.' } />
        </Helmet>
        <div>
          <div className="r3__section r3--no-head r3--dark">
            <div className="r3__content">
              <Reveal effect="fadeInLeft">
                <h1>Case Studies</h1>
                <Link to={'/work'} className='heading-link'>Work Archive</Link>
              </Reveal>
            </div>
          </div>

          <div className="r3__section">
            <div className="r3__content r3__case-item__page">
              { caseStudies.map((el, i) => {
                return (
                  <div className="r3__case-item" key={i}>
                    <div className="r3__thumb" style={{backgroundImage: `url(${el.node.acf.video_placeholder && el.node.acf.video_placeholder.source_url})`}}></div>
                    <div className="r3__thumb-content">
                      <h4>{ el.node.acf.title }</h4>
                      <span>Launch Case Study →</span>
                    </div>
                    <Link to={`/case-study/${el.node.slug}`}></Link>
                  </div>
                )
              }) }
            </div>
          </div>

        </div>
      </TemplateWrapper>
    )
  }
}

export default CaseStudyPage

export const pageQuery = graphql`
  query currentCaseStudiesCategoryQuery {
    allWordpressWpCaseStudy {
      edges {
        node {
          slug
          title
          acf {
            title
            video_placeholder {
              source_url
              title
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
        }
      }
    }
  }
`
